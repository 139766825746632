$(document).ready(function(){
    $(document).on('click','._2faqhc',function(){
        $("._1faqhd").css("height",'0');
        if($(this).next().hasClass('addedHeight') == false){
            var x=$(this).next().children().outerHeight();
            $(this).next().css('height',x);
            $(this).next().addClass('addedHeight');
        }else{
            $(this).next().css('height','0');
            $(this).next().removeClass('addedHeight');
        }
    });

       var tset=false;
        var wset=false;
        $(window).scroll(function(event){
            var height=$('.top-header').outerHeight()-100;
            var scroll=$(this).scrollTop();
            if(scroll>height){
                if(wset==false){
                    whitemenu()
                }
            }else{
                if(tset==false){
                    transparentmenu()
                }
            }
        });
        function whitemenu(){
            $(".menu-header").addClass('bg-myntax')
            $(".menu-header").addClass('box-shadow-1')
            tset=false;
            wset=true;
        }

        function transparentmenu(){
            $(".menu-header").removeClass('bg-myntax')
            $(".menu-header").removeClass('box-shadow-1')
            tset=true;
            wset=false;
        }

        if($(window).scrollTop()>$('.top-header').outerHeight()-100 && $('.top-header').attr('data-menu')=='false'){
            whitemenu()
        }else{
            transparentmenu()
        }



        // universal get selected and un selected

        $(document).on('click','.hprbtn',function(){
            if($(this).hasClass('button-not-selected')){
                $(".hprbtn").removeClass("button-selected");
                $(".hprbtn").addClass("button-not-selected");
                $(this).addClass('button-selected');
                $(this).removeClass("button-not-selected");
            }
        });

        //page locator
        $(window).scroll(function(){
            var x=$('body').innerHeight();
            var h=$(document).scrollTop();
            var wh=$(window).height();
            var percent=(h/(x-wh))*100;
            $('.set-loader').css('width',percent+"%");
        });

        //on scroll set the height
        $(window).on("scroll",function(){
            var y=$(window).scrollTop()+200;
            $('.top1').each(function(){
                var x=$(this).offset().top;
                var z=x+$(this).outerHeight();
                var ind=$(this).index()-1;
                if(x<y && z>=y){
                    $('.top1s').eq(ind).addClass('text-secondary-myntax');
                }else if(z<y){
                    $('.top1s').eq(ind).removeClass('text-secondary-myntax');
                }else{
                    $('.top1s').eq(ind).removeClass('text-secondary-myntax');
                }
            });
            
        });
    });

    //when click increase the height of faq
    